@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/bootstrap.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/common.scss";
// @import "swiper/swiper.min.css";
@import "./config";

:root,
[data-theme*="light"] {
  @include light-theme;
  --pro-range-bg: #{$pro-range-bg-light};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-light};
  --pro-invoice-label: #{$pro-invoice-label-light};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-light};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-light};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-light};
  --pro-staff-card-bg: #{$pro-staff-card-bg-light};
  --pro-shimmer-bg: #{$pro-shimmer-bg-light};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-light};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-light};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-light};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-light};
  --pro-green: #{$pro-green-light};
  --pro-toggle-bg: #{$pro-toggle-bg-light};
  --pro-pagination-shadow: #{$pro-pagination-shadow-light};
  --pro-primary-darken: #{$pro-primary-darken-light};
  --pro-table-link: #{$pro-table-link-light};
  --pro-dropdown-hover-bg: #{$pro-dropdown-hover-bg-light};
  --pro-instagram: #{$pro-instagram-light};
  --pro-youtube: #{$pro-youtube-light};
  --pro-facebook: #{$pro-facebook-light};
  --pro-google: #{$pro-google-light};
  --pro-tiktok: #{$pro-tiktok-light};
  --pro-secondary-light: #{$pro-secondary-light};
  --pro-bg-primary:#{$pro-bg-primary-light};
  --pro-task: #{$pro-task-light};
  --pro-input-hover-bg :  #{$pro-input-hover-bg-light};
  --pro-outline-active :  #{$pro-outline-active-light};
}

[data-theme*="dark"] {
  @include dark-theme;
  --pro-range-bg: #{$pro-range-bg-dark};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-dark};
  --pro-invoice-label: #{$pro-invoice-label-dark};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-dark};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-dark};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-dark};
  --pro-staff-card-bg: #{$pro-staff-card-bg-dark};
  --pro-shimmer-bg: #{$pro-shimmer-bg-dark};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-dark};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-dark};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-dark};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-dark};
  --pro-green: #{$pro-green-dark};
  --pro-toggle-bg: #{$pro-toggle-bg-light};
  --pro-pagination-shadow: #{$pro-pagination-shadow-dark};
  --pro-primary-darken: #{$pro-primary-darken-dark};
  --pro-table-link: #{$pro-table-link-dark};
  --pro-dropdown-hover-bg: #{$pro-dropdown-hover-bg-dark};
  --pro-instagram: #{$pro-instagram-dark};
  --pro-youtube: #{$pro-youtube-dark};
  --pro-facebook: #{$pro-facebook-dark};
  --pro-google: #{$pro-google-dark};
  --pro-tiktok: #{$pro-tiktok-dark};
  --pro-secondary-light: #{$pro-secondary-dark};
  --pro-bg-primary:#{$pro-bg-primary-dark};
  --pro-task: #{$pro-task-dark};
  --pro-input-hover-bg :  #{$pro-input-hover-bg-dark};
  --pro-outline-active :  #{$pro-outline-active-dark};
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: var(--pro-input-focus-shadow);
    background-color: #e2e2e2;
  }

  scrollbar-width: thin;
}

body {
  font-size: $font-size-normal;
  line-height: $primary-line-height;
  --toastify-toast-min-height: 56px;
  --toastify-z-index: 999;
}

.pro-toast-container {
  top: 57px !important;

  .pro-toast-progress {
    height: 2px;
  }
}

.wrapper {
  width: max-content;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;

  >div {
    height: 100%;
  }
  
}

.wrapper-table {
  flex: 1;
  width: calc(100% - 260px);
  overflow: hidden;
  min-height: calc(100vh - 90px);

  .pro-pagination {
    display: flex;
    justify-content: center;
  }

  &.pt-0 {
    padding-top: 0 !important;
  }
  &.grey_bg{
    background-color: #f6f6f6;
  }
}

.pro-overflow-initial {
  overflow: initial !important;
}

.wrapper-tables {
  width: 100%;
  overflow: hidden;
}

.pt-custom {
  padding-top: size(57);
}

.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}

.pro-btn{
  &.pro-btn-outline-primary {
    --pro-button-color: var(--pro-primary);
    --pro-button-hover-color: #ffffff !important;
    border: 1px solid var(--pro-primary);
    --pro-button-hover-bg: var(--pro-primary);
    --pro-button-padding-y: #{size(8)};

    &:active {
      background-color: var(--pro-primary);
    }
  }
  &.pro-btn-success{
    --pro-button-bg: var(--pro-success);
    --pro-button-hover-bg: var(--pro-light);
    --pro-button-active-bg: var(--pro-light);
    --pro-button-color: var(--pro-light);
    --pro-button-hover-color: var(--pro-success);
    border: 1px solid var(--pro-success);
  }
  &.pro-btn-outline-success{
    --pro-button-color: var(--pro-success);
    --pro-button-hover-color: #ffffff !important;
    border: 1px solid var(--pro-success);
    --pro-button-hover-bg: var(--pro-success);
    --pro-button-padding-y: #{size(8)};
  }
  &.pro-btn-danger{
    --pro-button-bg: var(--pro-danger);
    --pro-button-hover-bg: var(--pro-light);
    --pro-button-active-bg: var(--pro-light);
    --pro-button-color: var(--pro-light);
    --pro-button-hover-color: var(--pro-danger);
    border: 1px solid var(--pro-danger);
  }
  &.pro-btn-outline-danger{
    --pro-button-color: var(--pro-danger);
    --pro-button-hover-color: #ffffff !important;
    border: 1px solid var(--pro-danger);
    --pro-button-hover-bg: var(--pro-danger);
    --pro-button-padding-y: #{size(8)};
  }
  
}


.pro-bg-primary {
  background-color: var(--pro-bg-primary) !important;
  color: #fff !important;
}

.app-loading {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  &::after {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pro-primary);
    border-radius: 50%;
    overflow: hidden;
    border-bottom-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 0.6s infinite linear;
  }

  &.fill {
    position: fixed;
  }
}

@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.pro-pnt {
  color: var(--pro-table-link);
  font-weight: 600; // no variables for fw-semibold
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.shimmer {
  background: var(--pro-shimmer-bg);
  background-image: linear-gradient(to right,
      var(--pro-shimmer-gradient-color1) 0%,
      var(--pro-shimmer-gradient-color2) 20%,
      var(--pro-shimmer-gradient-color3) 40%,
      var(--pro-shimmer-gradient-color4) 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;

  &.letter {
    width: 100%;
    height: 15px;

    &.sub {
      width: 90%;
    }

    &.para {
      height: 6px;
    }
  }

  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.input-country-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  .input-main-wrap {
    position: relative;
    width: 100%;

    .code-wrap {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.34375rem 0.4375rem;
      font-size: 0.85rem;
      max-width: 84px;
      cursor: pointer;
      user-select: none;

      &::after {
        position: absolute;
        content: "";
        width: 1px;
        top: 5px;
        bottom: 5px;
        right: 0;
        background-color: var(--pro-border-color);
      }

      .pro-input__control {
        border: 0;
        box-shadow: none !important;
      }

      .pro-input__value-container {
        padding: 0;
      }

      .pro-input__indicator-separator {
        display: none;
      }

      .pro-input__placeholder {
        font-size: 14px;
        color: var(--pro-dark);
      }

      .pro-input__indicator {
        padding: 4px;
      }

      .pro-input__menu-list {
        max-height: 250px;
      }

      .pro-input__menu {
        width: max-content;
        max-width: 300px;
      }
    }

    .pro-input {
      padding-left: 6rem;
    }
  }

  .error-text {
    font-size: 12px;
    line-height: $secondary-line-height;
    margin-top: 10px;
    color: var(--pro-danger);
  }
}

.pro-input__option {
  &:not(.pro-input__option--is-selected).pro-input__option--is-focused {
    background-color: var(--pro-input-option-hover-bg);
  }

  &:active {
    background-color: var(--pro-input-option-active-bg);
  }

  &.pro-input__option--is-selected {
    background-color: var(--pro-primary) !important;
    color: var(--pro-light) !important;
  }
}

.hide-icon-input {
  padding-right: 40px;
}

.p-re {
  position: relative;

  .input-wrap {
    position: relative;
  }

  .hide-icon {
    right: 12px;
  }
}

.hide-icon {
  position: absolute;
  right: 0;
  top: 24px;
  border: none;
  background-color: transparent;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .pro-btn-link .material-symbols-outlined {
  font-size: 1.5rem !important;
}

.dropdown .dropdown-item {
  transition: all 0.3s ease-in;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--pro-dark);
  background-color: var(--pro-dropdown-hover-bg);
}

.pro-table th.checkbox-cell,
.pro-table td.checkbox-cell {
  min-width: 44px;
}

.no-border {
  .pro-input__control {
    border: none;
    cursor: pointer;
  }

  .pro-input__indicators .pro-input__indicator-separator {
    display: none;
  }
}

.goldBasedErr {
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  color: var(--pro-danger);
}

.table-wrap{
  margin-bottom: 60px;
}
.pro-table {
  background-color: #fff;
  ul {
    li {
      position: relative;
      padding: size(4) 0;
      padding-left: size(15);

      &:before {
        position: absolute;
        left: 0;
        top: 12px;
        content: "";
        background-color: var(--pro-primary);
        width: size(5);
        height: size(5);
        border-radius: 50%;
      }
    }
  }
}

.input-wrap label:empty {
  display: none;
}

.pro-pagination-wrapper {
  z-index: 5;
}

.pro-h-100 {
  height: 100%;
}

.no-wrap {
  white-space: pre;
}

// .pro-btn {
//   .material-symbols-outlined {
//     max-width: 16px;
//   }
// }
.scroll-lg {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.socia-icons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 7px;

  .icon-wrap {
    width: 30px;

    img {
      object-fit: contain;
    }
  }
}

.pro-toggle {
  display: flex;
  align-items: center;

  .pro-toggle-box {
    position: relative;
    width: 28px;
    height: 16px;

    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      appearance: none;
      background: var(--pro-toggle-bg);
      border-radius: 100px;
      cursor: pointer;
      transition: 0.4s;
    }

    input:checked[type="checkbox"] {
      background: var(--pro-primary);
    }

    input[type="checkbox"]~span {
      position: absolute;
      content: "";
      width: 13px;
      height: 13px;
      top: 50%;
      left: 2px;
      background: var(--pro-light);
      border-radius: 50%;
      transition: 0.3s;
      pointer-events: none;
      transform: translateY(-50%);
    }

    input:checked[type="checkbox"]~span {
      left: 48%;
    }

    &+label {
      margin-left: 12px;
      color: var(--pro-input-text);
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

label {
  color: var(--pro-input-text);
}

.pro-check-box .pro-check[type="radio"] {
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border-color: var(--pro-input-text);

  &:checked,
  &:checked:hover {
    border-color: var(--pro-input-text);
    background-color: var(--pro-light);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' style='&%2310;'%3E%3Ccircle r='2' fill='%234c4c4c' style='&%2310;'/%3E%3C/svg%3E");
  }
}

.pro-badge {
  &.badge-warning-outline {
    --wac-badge-bg: transparent;
    --wac-badge-color: #e1970c;
    --wac-badge-border-color: #e1970c;
  }

  &.badge-danger-outline {
    --wac-badge-bg: transparent;
    --wac-badge-color: #da1f1f;
    --wac-badge-border-color: #da1f1f;
  }

  &.badge-pending-outline {
    --wac-badge-bg: transparent;
    --wac-badge-color: #8b8b8b;
    --wac-badge-border-color: #8b8b8b;
  }

  &.badge-primary-light {
    --wac-badge-bg: #f4f5f7;
    --wac-badge-color: #000000;
    --wac-badge-border-color: #f4f5f7;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 16px;
  }

  &.badge-success {
    --wac-badge-bg: var(--pro-success);
    --wac-badge-color: var(--pro-light);
    --wac-badge-border-color: var(--pro-success);
  }
}

.pro-back-btn {
  position: relative;
  background-color: transparent;
  border: 0;
  color: var(--pro-dark);

  .icon {
    background-color: var(--pro-primary);
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-right: 1rem;
    font-weight: 600;
  }

  .title {
    transition: all 0.3s;
  }

  &:hover .title {
    color: var(--pro-primary);
  }
}

.pro-border-bottom {
  border-bottom: 1px solid var(--pro-outline-border);
}

.pro-border-right {
  border-right: 1px solid var(--pro-outline-border);
}

.pro-border-top {
  border-top: 1px solid var(--pro-outline-border);
}

.pro-border-left {
  border-left: 1px solid var(--pro-outline-border);
}

.pro-pagination-wrapper {
  z-index: 9;
  box-shadow: 0px 0px 4px 0px var(--pro-pagination-shadow);

  .pro-input .pro-input__menu {
    overflow: hidden;
  }
}

.error-custom {
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  color: var(--pro-danger);

  display: inline-block;
}

.pro-badge.badge-outline-primary {
  --wac-badge-bg: transparent;
  --wac-badge-color: var(--pro-primary);
  --wac-badge-border-color: var(--pro-primary);
}

.pro-text-error {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  color: var(--pro-danger);
}

.pro-text-underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}

[data-theme*="dark"] {
  .btn-close {
    filter: invert(1);
  }

  .pro-input .pro-input__menu {
    box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 10%),
      0 4px 11px hsl(0deg 0% 100% / 10%);
  }
}

.categories_types {
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 12px;
    display: block;

    strong {
      font-weight: 600;
    }
  }

  .tags_wrap {
    a {
      font-size: 12px;
      line-height: 1.3;
      color: #000000;

      &.active {
        color: var(--pro-primary);
      }
    }
  }
}

.accordion {
  .accordion-header {
    position: relative;

    .accordion-button {
      position: relative;
      width: 100%;
      border: 0;
      box-shadow: none;
      padding: 12px 34px 12px 12px;
      margin: 0;
      background-color: transparent;

      &::after {
        content: "";
        position: absolute;
        top: 18px;
        right: 14px;
        width: 7px;
        height: 7px;
        border-left: 2px solid var(--pro-dark);
        border-bottom: 2px solid var(--pro-dark);
        transform: rotate(-45deg);
        z-index: 1;
        transition: all 0.3s ease-in-out;
      }

      &:not(.collapsed):after {
        transform: rotate(134deg);
        top: 22px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .accordion-collapse {
    padding: 0 12px 12px;
  }
}

.social-accdn-wrap {
  .accordion-item {
    border-radius: 10px;
    overflow: hidden;

    &.instagram {
      background-color: var(--pro-instagram);
    }
    &.tasks {
      background-color: var(--pro-task);
    }
    &.youtube {
      background-color: var(--pro-youtube);
    }

    &.facebook {
      background-color: var(--pro-facebook);
    }

    &.google {
      background-color: var(--pro-google);
    }

    &.tiktok {
      background-color: var(--pro-tiktok);
    }
  }

  .accordion-header {
    font-size: 14px;
    line-height: 1.3;
    color: var(--pro-dark);

    .social_wrap {
      font-size: 14px;
      color: var(--pro-dark);

      .amount {
        font-size: 16px;
        color: var(--pro-dark);
      }
    }
  }
}

.form-preloader-wrap {
  position: absolute;
  overflow: hidden;
  width: 96%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 64px;
    width: 100%;

    img {
      max-width: 100%;
    }
  }
}
.amount_wrap{
  color: var(--pro-dark);
}

.social-slider {

  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;

    &::after {
      color: #000;
      font-size: 13px;
    }
  }

  .swiper-button-next {
    right: 4px;
  }

  .swiper-button-prev {
    left: 4px;
  }

  .swiper-slide {
    padding: 0 50px;

    p {
      font-size: 12px;
    }

    h5 {
      font-size: 18px;
    }
  }
}

.icon_wallet {
  max-width: 33px;
  width: 100%;
  height: 25px;
}

.btn-spinner {
  width: 32px;
  height: 14px;
  display: block;
  margin: auto;

  img {
    max-width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.pro-rtl {
  direction: rtl;
  width: 100%;
}

.pro-ltr {
  direction: ltr;
  width: 100%;
}

.video-wrap {
  position: relative;
  padding-top: 56.25%;
  margin: 10px 0 20px;

  >div {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #000;

  }
}
.pro-pnt-none{
  pointer-events: none;
}
.icon-check{
  color:#14ae09;
}
.icon-close{
  color: #cd2a1d;
}
.pagination-w-100{
  .pro-pagination-wrapper{
    width: 100% !important;
  }
}
.multi-cols-tbl{
  tr{
    td{
      
      &:first-child{
        font-weight: 500;
        font-size: 13px;
        padding-right: 5px;
        p{
          font-weight: 500;
          font-size: 13px;
        }
      }
      &:last-child{
        font-weight: 400;
        font-size: 14px;
        padding-left: 5px;
        p{
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}
.shimmer {
  background: var(--pro-shimmer-bg);
  background-image: linear-gradient(
    to right,
    var(--pro-shimmer-gradient-color1) 0%,
    var(--pro-shimmer-gradient-color2) 20%,
    var(--pro-shimmer-gradient-color3) 40%,
    var(--pro-shimmer-gradient-color4) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: shimmer;
  -webkit-animation-name: shimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  &.letter {
    width: 100%;
    height: 15px;
    &.sub {
      width: 90%;
    }
    &.para {
      height: 6px;
    }
  }
  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}
.error-text {
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  color: var(--pro-danger);
}
.desc_break{
  *{
    word-break: break-word;
  }
}