.dashboard_wrap {
  padding: 0 28px;
}
.graph_box{
  >div{
      height: 100%;
      justify-content: space-between;
  }
  canvas{
    max-height: 300px;
  }
  .title_wrap{
    margin-left: -16px;
    margin-right: -16px;

  }
}
.tbl_col_title { 
  .title_wrap{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    & + div{
      padding-top: 0 !important;
    }
    table{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
 
  table {
    tbody {
      tr {
        td {
          &:first-child {
            font-weight: 600;
            background-color: #fff;
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }
    }

    thead,
    tbody {
      th {
        
        &:first-child {
          &::before {
            top: 0;
            bottom: 0;
          }
        }
      }

      td {
        &:first-child {
          &::before {
            top: 0;
            bottom: 0;
          }
        }
      }
    }
  }

}

.doughnut_chart_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .doughnut_chart {
    width: 40%; // Adjust width to match your design
    // height: 150px; // Ensure it's square
    flex: 0 0 40%;
  }

  .doughnut_chart_labels {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        padding: 16px 0;
        font-size: 14px;
        font-weight: 400;
        color: #626266;
        border-bottom: 1px solid #F1F1F1;
        &:last-child{
          border: 0;
        }

        .icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .count {
          font-weight: 600;
          margin-left: auto;
        }
      }
    }
  }
}
